export default defineNuxtRouteMiddleware((to, from) => {
  if (isToRouteBlocked(to.name.toString())) {
    return navigateTo({ name: 'request-assets' })
  }
})

function isToRouteBlocked(name: string) {
  switch (name) {
    case 'request':
      return true
    case 'request-timing':
      return isTimingRouteBlocked()
    case 'request-requirements':
      return isRequirementsRouteBlocked()
    case 'request-company':
      return isCompanyRouteBlocked()
    case 'request-success':
      return isSummaryRouteBlocked()
  }
}

function isTimingRouteBlocked() {
  const { requestData } = useOrderRequestParams()
  return requestData.value.items.length == 0
}

function isRequirementsRouteBlocked() {
  const requestData = useOrderRequestParams().requestData.value
  return (
    requestData.items.length == 0 ||
    !requestData.startingDate ||
    !requestData.duration
  )
}

function isCompanyRouteBlocked() {
  const requestData = useOrderRequestParams().requestData.value
  return (
    requestData.items.length == 0 ||
    !requestData.startingDate ||
    !requestData.duration
  )
}

function isSummaryRouteBlocked() {
  const requestData = useOrderRequestParams().requestData.value
  return (
    requestData.items.length == 0 ||
    !requestData.startingDate ||
    !requestData.duration ||
    // !requestData.address ||
    !requestData.company ||
    // !requestData.firstName ||
    // !requestData.lastName ||
    // !requestData.mail ||
    !requestData.phone
  )
}
